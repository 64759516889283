import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, map } from 'rxjs';
import { Person } from '../models/person.model';

@Injectable({
  providedIn: 'root'
})
export class PersonService extends BaseService {

  constructor(private http: HttpClient) {
    super(http);
  }

  find(entity: string, filter?: string): Observable<Person[]> {
    let httpParams = new HttpParams();
    if (!!filter) {
      httpParams = httpParams.set('value', filter);
    }

    return super.get<any[]>(
      `${entity}/Find`,
      httpParams)
      .pipe(
        map((response: any[]) => response.map(customers => customers))
      );
  }

  search(entity: string, filter?: string, hasAccount?: boolean, hasUser?: boolean, currentPage?: number, itemsPerPage?: number, lastUpdateDate?: string): Observable<Person[]> {
    let httpParams = new HttpParams();
    if (!!filter) {
      httpParams = httpParams.set('Search', filter);
    }

    if (hasAccount != null) {
      httpParams = httpParams.set('HasAccount', hasAccount);
    }

    if (hasUser != null) {
      httpParams = httpParams.set('HasUser', hasUser);
    }

    if (!!currentPage) {
      httpParams = httpParams.set('Page', currentPage);
    }

    if (!!itemsPerPage) {
      httpParams = httpParams.set('ItemsPerPage', itemsPerPage);
    }

    if (!!lastUpdateDate) {
      httpParams = httpParams.set('LastUpdateDate', lastUpdateDate);
    }

    return super.get<any[]>(
      `${entity}/search`,
      httpParams)
      .pipe(
        map((response: any[]) => response.map(customers => customers))
      );
  }

  delete(entity: string, id: string): Observable<any> {
    return super.deleteMethod(
      `${entity}/${id}`);
  }

  deleteTelegramChatId(entity: string, id: string): Observable<any> {
    return super.put(
      `${entity}/DeleteChatId/${id}`, id);
  }

  create(entity: string, data: Person, adminUser: boolean = false) {
    return super.post<Person>(
      `${entity}${adminUser ? '/user' : ''}`,
      data
    );
  }

  edit(entity: string, data: Person, adminUser: boolean = false): Observable<Person> {
    return super
      .put<Person>(
        `${entity}${adminUser ? '/user' : ''}/${data.id}`,
        data
      );
  }

  getById(entity: string, id: string): Observable<Person> {
    return super.get(`${entity}/${id}`);
  }
}
